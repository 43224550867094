import { Directive, ElementRef, HostBinding, HostListener, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { LoadingStore } from '../stores/loading.store';

/**
 * This directive is used to display a spinner on a button when the loading$ observable is true.
 */
@Directive({
  selector: '[appLoadingButton]',
  standalone: true
})
export class LoadingButtonDirective implements OnInit, OnDestroy {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private loadingStore = inject(LoadingStore);

  private loadingSubscription!: Subscription;
  private delayedLoadingSubscription!: Subscription;
  private spinnerVisible = false;
  @HostBinding('class.disabled-clicks') disabledClicks = false;

  /**
   * The delay before displaying the spinner, this is to avoid flickering when the loading is fast
   * @private
   */
  private readonly initialDelay = 300; // in milliseconds

  /**
   * When the user clicks on the button, we stop the event propagation and the default behavior
   * if the button is disabled
   * @param event
   */
  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (this.disabledClicks) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  /**
   * We've got two subscriptions to the loading$ observable:
   * - one to disable the button at the beginning of the loading process
   * - one to display the spinner after a delay to avoid flickering when the loading is fast
   */
  ngOnInit(): void {
    this.loadingSubscription = this.loadingStore.loading$.subscribe((loading) => {
      this.updateButtonState(loading, false);
    });

    this.delayedLoadingSubscription = this.loadingStore.loading$
      .pipe(debounceTime(this.initialDelay))
      .subscribe((loading) => {
        this.updateButtonState(loading, true);
      });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
    this.delayedLoadingSubscription.unsubscribe();
  }

  /**
   * Creates the spinner using the font awesome spinner and append it to the button
   * @private
   */
  private addSpinner(): void {
    const spinner = this.renderer.createElement('i');
    this.renderer.addClass(spinner, 'fa-solid');
    this.renderer.addClass(spinner, 'fa-spinner');
    this.renderer.addClass(spinner, 'spinner');
    this.renderer.appendChild(this.el.nativeElement, spinner);
  }

  /**
   * Search for the spinner and remove it
   * @private
   */
  private removeSpinner(): void {
    const spinner = this.el.nativeElement.querySelector('.spinner');
    if (spinner) {
      this.renderer.removeChild(this.el.nativeElement, spinner);
    }
  }

  /**
   * Update the button state based on the loading state
   * The UI changes are delayed to avoid flickering when the loading is fast
   * but the button is disabled at the beginning of the loading process to avoid multiple clicks
   * @param loading
   * @param delayed
   * @private
   */
  private updateButtonState(loading: boolean, delayed: boolean): void {
    if (loading) {
      this.disabledClicks = true;
      if (delayed && !this.spinnerVisible) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
        this.addSpinner();
        this.spinnerVisible = true;
      }
    } else {
      this.disabledClicks = false;
      if (this.spinnerVisible) {
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        this.removeSpinner();
        this.spinnerVisible = false;
      }
    }
  }
}
